export class OrderItem
{
    #id;
    #image_url;
    #name;
    #sku;
    #barCode;
    #vat
    #unit

    note;
    quantity = 0;
    price = 0;
    discount = 0;
    discount_type = 'percent';
    showNote = false;

    constructor(id, image_url, name, sku, barCode, note, quantity = 0, price = 0, discount = 0, discount_type = 'percent', vat = 7, unit = "ชิ้น") {        
        if (discount_type != 'percent' && discount_type != 'value') throw "discount type can only be value or percent";

        this.#id = id;
        this.#image_url = image_url;
        this.#name = name;
        this.#sku = sku;
        this.#barCode = barCode;
        this.note = note;
        this.quantity = quantity;
        this.price = price;
        this.discount = discount;
        this.discount_type = discount_type;
        this.#vat = vat;
        this.#unit = unit;

        if (!!note) this.showNote = true;
    }

    get unit() {
        return this.#unit
    }

    get discountInValue() {        
        if (this.discount_type == 'percent') return (this.discount * this.price / 100).toFixed(2);

        return this.discount;
    }

    get priceAfterDiscount() {
        return this.price - this.discountInValue
    }

    get priceSum() {
        return (this.priceAfterDiscount * this.quantity).toFixed(2);
    }

    get priceBeforeVAT() {
        if (this.#vat == 0) return 0;

        return (this.priceSum * 100 / (100 + this.#vat * 1)).toFixed(2);
    }

    get is_vat() {
        if (!this.#vat) return false

        if (this.#vat == 0) return false

        return true
    }

    get VAT() {
        return this.#vat
    }

    get VATInValue() {
        if (this.#vat == 0) return 0;

        return (this.priceSum - this.priceBeforeVAT).toFixed(2);
    }

    get id() {
        return this.#id;
    }

    get name() {
        return this.#name;
    }

    get SKU() {
        return this.#sku;
    }

    get sku() {
        return this.#sku;
    }

    get barCode() {
        return this.#barCode;
    }

    get imageURL() {
        return this.#image_url
    }

    set imageURL(image_url) {
        this.#image_url = image_url
    }    
}