<template>
    <v-tooltip 
    top
    v-model="showTooltip"
    attach
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-bind="$attrs"
            v-on="on"        
            :append-icon="icons.mdiContentCopy"
            readonly                        
            type="text"
            @click.self="copyText"
            @click:append="copyText"
            ref="copyTextField"            
            >             
            </v-text-field>
        </template>
        <span class="mx-auto">คัดลอกแล้ว</span>
    </v-tooltip>
    <!--
    <div>
        <v-text-field
        v-bind="$attrs"
        v-on="$listeners"        
        :append-icon="icons.mdiContentCopy"
        readonly                        
        type="text"
        @click.self="copyText"
        @click:append="copyText"
        ref="copyTextField"
        >
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
        </v-text-field>
        <v-tooltip 
        top
        v-model="showTooltip"
        attach
        ref="toolTip"
        >
            <span class="mx-auto">คัดลอกแล้ว</span>
        </v-tooltip>
    </div>
    -->
</template>
<script>
import { mdiContentCopy } from '@mdi/js'
export default {
  data() {
    return {
      isMounted: false,
      showTooltip: false,
      closeTimer: null,
      icons: {
        mdiContentCopy,
      },
    }
  },
  methods: {
    copyText(event) {
      const element = this.$refs.copyTextField.$refs.input

      if (!element) return

      element.select()
      element.setSelectionRange(0, 99999) // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(element.value)

      this.showTooltip = true

      if (this.closeTimer) clearTimeout(this.closeTimer)

      let that = this
      this.closeTimer = setTimeout(() => (that.showTooltip = false), 3000)
    },
  },
  mounted() {
    this.isMounted = true
  },
}
</script>