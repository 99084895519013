<template>
    <div>
        <div v-for="(order, page_index) in printData" :key="'page_' + page_index">
            <div style="
                width:100%;
                justify-content: center;
                page-break-after: always;            
                box-sizing:border-box;
                display:flex;                
                position:relative;
                padding: 30px;
                flex-direction:column;                
                "                
            >
                <div style="font-size:1.4rem">{{ company.name }}</div>                                                
                    <div style="font-size:1rem;margin-top:5px">{{ order.branch}}</div>                    
                    <div style="font-size:1rem;margin-top:5px">{{ order.headerAddress }}</div>
                    <div style="font-size:1rem;margin-top:5px">เบอร์ : {{order.tel}}</div>
                    <div style="font-size:1rem;margin-top:5px">เลขประจำตัวผู้เสียภาษี : {{company.taxid}}</div>
                <table style="font-size:1rem;border-spacing:0;width:100%;margin-top:15px">     
                    <thead>     
                        <tr>     
                            <td width="30%"></td>     
                            <td width="70%"></td>     
                        </tr>   
                    </thead>       
                    <tbody>    
                        <tr>    
                            <td colspan="2" style="border-top:1px dashed #000;padding-top:15px;font-weight:bold;font-size:1.4rem;padding-bottom:5px">ใบกำกับภาษีอย่างย่อ/ใบเสร็จรับเงิน</td>    
                        </tr>    
                        <tr>    
                            <td style="padding-bottom:5px">เลขที่</td>    
                            <td style="padding-bottom:5px">{{order.abbNumber}}</td>
                        </tr>
                        <tr>    
                            <td style="padding-bottom:5px">อ้างอิง</td>    
                            <td style="padding-bottom:5px">{{order.orderNumber}}</td>
                        </tr>  
                        <tr>    
                            <td style="padding-bottom:5px">วันที่</td>    
                            <td style="padding-bottom:5px">{{order.paidAt}}</td>
                        </tr>      
                        <tr>    
                            <td style="padding-bottom:5px">พนักงานขาย</td>    
                            <td style="padding-bottom:5px">{{ order.seller }}</td>    
                        </tr>      
                    </tbody>    
                </table> 
                <table style="font-size:1rem;border-spacing:0;width:100%;margin-top:15px">     
                    <thead>     
                        <tr>     
                            <td width="40%"></td>     
                            <td width="10%"></td>     
                            <td width="15%"></td>     
                            <td width="15%"></td>     
                            <td width="20%"></td>     
                        </tr>   
                    </thead>  
                    <tbody>    
                        <tr>   
                            <td colspan="5" style="border-top:1px dashed #000;padding-top:15px;padding-bottom:5px"></td>    
                        </tr>                                   
                        <tr v-for="(product,product_index) in order.orderItems" :key="'product_' + product_index">
                            <td style="padding-bottom:5px;">{{product.name}}</td>
                            <td style="padding-bottom:5px;text-align:center">{{product.quantity | formatNumber(0)}}</td>     
                            <td style="padding-bottom:5px;text-align:right">{{product.price * 1 | formatNumber}}</td>                                
                            <td style="padding-bottom:5px;text-align:right">{{ product.discount | formatNumber }} {{ product.discount_type == 'percent' ? '%' : '' }}</td>     
                            <td style="padding-bottom:5px;text-align:right">{{ product.priceSum | formatNumber }}</td>     
                        </tr> 
                    </tbody>  
                </table> 
                <table style="font-size:1rem;border-spacing:0;width:100%;margin-top:15px">  
                    <thead>     
                        <tr>     
                            <td width="65%"></td>     
                            <td width="35%"></td>     
                        </tr>   
                    </thead>  
                    <tbody>    
                        <tr>   
                            <td colspan="2" style="border-top:1px dashed #000;padding-top:15px;padding-bottom:5px"></td>    
                        </tr>   
                        <tr>     
                            <td style="padding-bottom:5px;text-align:right">รวมเป็นเงิน</td>    
                            <td style="padding-bottom:5px;text-align:right">{{ order.priceBeforeDiscount | formatNumber }}</td>    
                        </tr>     
                        <tr>     
                            <td style="padding-bottom:5px;text-align:right">ส่วนลด</td>    
                            <td style="padding-bottom:5px;text-align:right">{{ order.discountInValue | formatNumber }} {{ (order.discount_type == 'percent' ? '%' : '') }}</td>    
                        </tr>   
                        <tr>     
                            <td style="padding-bottom:5px;text-align:right">จำนวนเงินหลังหักส่วนลด</td>    
                            <td style="padding-bottom:5px;text-align:right">{{ order.priceAfterDiscount | formatNumber }}</td>    
                        </tr>   
                        <tr>     
                            <td style="padding-bottom:5px;text-align:right">ภาษีมูลค่าเพิ่ม 7.00%</td>    
                            <td style="padding-bottom:5px;text-align:right">{{order.VATSum | formatNumber}}</td>    
                        </tr>   
                        <tr>     
                            <td style="padding-bottom:5px;text-align:right">ราคาไม่รวมภาษีมูลค่าเพิ่ม</td>    
                            <td style="padding-bottom:5px;text-align:right">{{order.priceBeforeVAT | formatNumber}}</td>    
                        </tr>   
                    </tbody>  
                </table>
                <table style="font-size:1rem;border-spacing:0;width:100%;margin-top:15px;">  
                    <thead>     
                        <tr>     
                            <td width="65%"></td>     
                            <td width="35%"></td>     
                        </tr>   
                    </thead>  
                    <tbody>    
                        <tr> 
                            <td colspan="2" style="border-top:1px dashed #000;padding-top:15px;padding-bottom:5px"></td>    
                        </tr>   
                        <tr>     
                            <td style="padding-bottom:5px;text-align:right;font-weight:bold">(ราคารวมภาษีมูลค่าเพิ่ม) รวมทั้งสิ้น</td>    
                            <td style="padding-bottom:5px;text-align:right;font-weight:bold;font-size:1.2em">{{order.priceAfterDiscount | formatNumber}}</td>    
                        </tr>
                        <tr> 
                            <td colspan="2" style="border-bottom:1px solid #000;padding-top:15px"></td>    
                        </tr> 
                        <tr> 
                            <td colspan="2" style="border-bottom:3px solid #000;padding-top:1px"></td>    
                        </tr> 
                    </tbody>  
                </table>           
                <div v-if="!!order.trd || order.status == 'cancelled'" style="position: absolute;top:50%;left:50%;transform: translate(-50%, -50%) rotate(45deg);border-style: solid;border-color:red;padding:10px;color:red">
                    <div style="font-size:2.5em;text-align:center">ยกเลิก</div>
                    <div v-if="!!order.trd" style="font-size:1.25em;text-align:center">ออกใบกำกับแบบเต็มแล้ว</div>
                </div>
            </div>
        </div>   
    </div>
</template>
<script>
export default {
  props: {
    printData: {
      required: true,
      default: [],
    },
    company: {
      required: true,
      default: {},
    },
  },
}
</script>