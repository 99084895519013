import { asyncGet } from '@/helpers/asyncAxios';
import { Ability } from '@casl/ability';
import VueCookies from 'vue-cookies';

const defaultLoggedInAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]

export default {
    namespaced: true,
    state: {      
      access_token: null,
      user: null,      
      ability: null,
      ws_token: null
    },
    getters: {
        isLogin (state) {
          /*
          const access_token = VueCookies.get('access_token');

          return access_token ? true : false;            
          */
          return state.access_token ? true : false;            
        },
        hasAbility(state) {
          return state.ability ? true : false
        },
        isVerifiedEmail (state) {
          return state.user.email_verified_at ? true : false
        },
        verifyEmailBefore (state) {
          return new Date(state.user.verification_before)
        },
        GET_USER_ID(state) {
          return state.user?.id ? state.user.id : '-'
        },
        GET_USER_NAME(state) {
          return state.user?.name ? state.user.name : '-'
        },
        GET_USER_LASTNAME(state) {
          return state.user?.lastname ? state.user.lastname : '-'
        },
        GET_USER_FULLNAME(state) {
          return state.user?.name ? state.user.name + ' ' + state.user.lastname : '-'
        },
        GET_USER_IMAGE(state) {        
          return state.user?.fb_profile ? state.user.fb_profile : null
        },
        GET_ACCESS_TOKEN(state) {
          return state.access_token
        },
        GET_ABILITY(state) {
          return state.ability
        }
    },
    mutations: {
      LOGIN(state, { accessToken, wsToken }) {
        state.access_token = accessToken;
        state.ws_token = wsToken;
        VueCookies.set('access_token', accessToken);
        VueCookies.set('ws_token', wsToken);
      },
      LOGOUT(state) {
        state.user = null;
        state.access_token = null;
        state.ws_token = null;
        VueCookies.remove('access_token');
      },
      SET_USER_PROFILE(state, payload) {
        state.user = payload
      },
      SET_USER_ABILITY(state, payload) {        
        state.ability = new Ability(payload)
      }
    },
    actions: {
      FETCH_ME({ dispatch, commit, state, getters }) {               
        return new Promise(async (resolve, reject) => {          
          try {
            const {user} = await asyncGet('/me')
            
            resolve(commit('SET_USER_PROFILE', user));
          } catch (error) {
            reject(error)            
          }
        });        
      },
      FETCH_USER_ABILITY({ dispatch, commit, state, getters }, companyId = null) {               
        return new Promise(async (resolve) => {          
          
          if (!companyId && !getters.isLogin) return resolve(commit('SET_USER_ABILITY', []));                
          
          if (!companyId && getters.isLogin) return resolve(commit('SET_USER_ABILITY', defaultLoggedInAbility));

          try {
            const response = await asyncGet('/company/' + companyId + '/ability')

            const { ability } = response;            

            const formedAbility = [
              ...ability,
              ...defaultLoggedInAbility
            ]                      
            
            return resolve(commit('SET_USER_ABILITY', formedAbility));
          } catch (error) {

            if (getters.isLogin) return commit('SET_USER_ABILITY', defaultLoggedInAbility);
            
            return resolve(commit('SET_USER_ABILITY', []));
          }
        });        
      },
    },
  }
  