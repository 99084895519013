export default [
    {
        path: '/c/:company_id/po',
        name: 'po',
        component: () => import('@/views/procure/po/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'BuyOrder',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/po/:po_id',
        name: 'po_create',
        component: () => import('@/views/procure/po/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'BuyOrder',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/rog',
        name: 'rog',
        component: () => import('@/views/procure/rog/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'BuyOrder',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/rog/:rog_id',
        name: 'rog_create',
        component: () => import('@/views/procure/rog/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'BuyOrder',
            action: 'read'
        },
    },
]