export default [
    {
        path: '/c/:company_id/product',
        name: 'product',
        component: () => import('@/views/info/product/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/product/export',
        name: 'product_export',
        component: () => import('@/views/info/product/export.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'update'
        },
    },
    {
        path: '/c/:company_id/product/import',
        name: 'product_import',
        component: () => import('@/views/info/product/import.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'update'
        },
    },
    {
        path: '/c/:company_id/product/:product_id',
        name: 'product_create',
        component: () => import('@/views/info/product/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'update'
        },
    },    
    {
        path: '/c/:company_id/category',
        name: 'category',
        component: () => import('@/views/info/category/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/category/:category_id',
        name: 'category_create',
        component: () => import('@/views/info/category/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'update'
        },
    },
    {
        path: '/c/:company_id/tag',
        name: 'tag',
        component: () => import('@/views/info/tag/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/tag/:tag_id',
        name: 'tag_create',
        component: () => import('@/views/info/tag/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'update'
        },
    },
    {
        path: '/c/:company_id/supplier',
        name: 'supplier',
        component: () => import('@/views/info/supplier/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/supplier/:supplier_id',
        name: 'supplier_create',
        component: () => import('@/views/info/supplier/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'update'
        },
    },
    {
        path: '/c/:company_id/brand',
        name: 'brand',
        component: () => import('@/views/info/brand/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/brand/:brand_id',
        name: 'brand_create',
        component: () => import('@/views/info/brand/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Product',
            action: 'update'
        },
    },
]