export default [
    {
        path: '/verifycode',
        name: 'verifycode',
        component: () => import('@/views/VerifyCode.vue'),
        meta: {
            layout: 'withheader',
            resource: 'Auth',
            action: 'read'
        },
    },
]