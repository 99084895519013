export default [
    {
        path: '/c/:company_id/trd',
        name: 'trd',
        component: () => import('@/views/document/trd/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Document',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/trd/export',
        name: 'trd_export',
        component: () => import('@/views/document/trd/export.vue'),
        meta: {
            layout: 'content',      
            resource: 'Document',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/trd/:trd_id',
        name: 'trd_create',
        component: () => import('@/views/document/trd/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Document',
            action: 'read'
        },
    },    
]