import company from './company'
import customer from './customer'
import document from './document'
import inventory from './inventory'
import misc from './misc'
import order from './order'
import pos from './pos'
import procure from './procure'
import product from './product'
import report from './report'
import verification from './verification'

export default [
    {
        path: '/join/:uuid',
        name: 'join',
        component: () => import('@/views/Join.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/c/:company_id/home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            layout: 'content',      
            resource: 'Auth',
            action: 'read'
        },
    },
    ...pos,
    ...procure,
    ...product,
    ...inventory,
    ...company,
    ...verification,
    ...order,
    ...misc,
    ...customer,
    ...report,
    ...document
]