import CompanyLogo from '@/components/CompanyLogo.vue'
import CopyBox from '@/components/CopyBox.vue'
import DelayAutocomplete from '@/components/DelayAutocomplete.vue'
import DelayCombobox from '@/components/DelayCombobox.vue'
import DelayDatatable from '@/components/DelayDatatable.vue'
import LightBox from '@/components/LightBox.vue'
import ProductImage from '@/components/ProductImage.vue'
import VThailandAddressAutoComplete from '@/components/VThailandAddressAutoComplete.vue'
import { convertOrderStatusToColor, convertOrderStatusToText, convertTimestampToAooDefault } from '@/helpers/converter'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueFileAgent from 'vue-file-agent'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueLazyLoad from 'vue-lazyload'
import Notifications from 'vue-notification'
import { SlickItem, SlickList } from 'vue-slicksort'
import App from './App.vue'
import dialog from './mixins/dialog'
import globalMethod from './mixins/globalMethod'
import './plugins/acl'
import errorParser from './plugins/errorparser'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

Vue.config.productionTip = false

Vue.component('VThailandAddressAutoComplete', VThailandAddressAutoComplete);
Vue.component('DelayAutocomplete', DelayAutocomplete);
Vue.component('DelayCombobox', DelayCombobox);
Vue.component('DelayDatatable', DelayDatatable);
Vue.component('LightBox', LightBox);
Vue.component('CopyBox', CopyBox);
Vue.component('vfa-sortable-list', SlickList);
Vue.component('vfa-sortable-item', SlickItem);
Vue.component('product-image', ProductImage);
Vue.component('CompanyLogo', CompanyLogo);

Vue.use(VueFileAgent);
Vue.use(Notifications)
Vue.use(VueLazyLoad)
Vue.use(Notifications)
Vue.use(VueHtmlToPaper);


Vue.use(VueCookies);

Vue.filter('cutText', function (text, length) {
  if (text && text.length > length)
    return text.slice(0, length).toString() + '...';
  return text;
});

Vue.filter('formatNumber', function (number, tofixed = 2) {
  if (Number.isFinite(number) && tofixed > 0) number = number.toFixed(tofixed)
  
  if (!Number.isNaN(number))
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return 'ไม่สามารถประมวลผลได้';
});

Vue.filter('timestampToText', function (timestamp) {
    return convertTimestampToAooDefault(timestamp)
});

Vue.filter('orderStatusToColor', function (status) {
  return convertOrderStatusToColor(status)
});

Vue.filter('orderStatusToText', function (status) {
  return convertOrderStatusToText(status)
});

Vue.filter('number2Text', function (number) {
  if (number == 0)
    return 'ศูนย์บาทถ้วน';
  let matches = number.toString().match(/([1-9][0-9]*)\.?([0-9]*)/);
  let baht = '';
  let satang = '';

  for (let i = 1; i < matches.length; i++) {
    let total_length = matches[i].length;
    if (i == 2 && total_length == 1) {
      matches[i] += "0";
      total_length++;
    }

    let digit = '';
    let is_et = false;
    for (let x = 0; x < total_length; x++) {
      switch (matches[i][x] * 1) {
        case 1:
          if (is_et) {
            digit += "เอ็ด";
            is_et = false;
          }
          else if (total_length == 1) {
            digit += "หนึ่ง";
          }
          else if ((total_length - x) % 7 + 1 == 2) {

          }
          else if (total_length - x != 2) {
            digit += "หนึ่ง";
          }
          break;
        case 2:
          if (total_length - x == 2) {
            digit += "ยี่";
          }
          else {
            digit += "สอง";
          }
          break;
        case 3:
          digit += "สาม";
          break;
        case 4:
          digit += "สี่";
          break;
        case 5:
          digit += "ห้า";
          break;
        case 6:
          digit += "หก";
          break;
        case 7:
          digit += "เจ็ด";
          break;
        case 8:
          digit += "แปด";
          break;
        case 9:
          digit += "เก้า";
          break;
        default:
          break;
      }
      if (matches[i][x] != 0) {
        if (total_length - x == 2) {
          digit += "สิบ";
          is_et = true;
        }
        else if (total_length - x == 3)
          digit += "ร้อย";
        else if (total_length - x == 4)
          digit += "พัน";
        else if (total_length - x == 5)
          digit += "หมื่น";
        else if (total_length - x == 6)
          digit += "แสน";
        else if (total_length <= 7 && total_length - x == 7)
          digit += "ล้าน";
        else if ((total_length - x) > 7 && (total_length - x) % 7 + 1 == 2) {
          digit += "สิบ";
          is_et = true;
        }
        else if ((total_length - x) > 7 && (total_length - x) % 7 + 1 == 3)
          digit += "ร้อย";
        else if ((total_length - x) > 7 && (total_length - x) % 7 + 1 == 4)
          digit += "พัน";
        else if ((total_length - x) > 7 && (total_length - x) % 7 + 1 == 5)
          digit += "หมื่น";
        else if ((total_length - x) > 7 && (total_length - x) % 7 + 1 == 6)
          digit += "แสน";
        else if ((total_length - x) >= 7 && (total_length - x) % 7 == 0)
          digit += "ล้าน";
      }
    }
    if (i == 1) {
      baht += digit + "บาท";
    }
    else if (i == 2) {
      if (digit != "")
        satang += digit + "สตางค์";
    }
  }

  if (satang == "")
    baht += "ถ้วน";

  return baht + satang;
});

Vue.prototype.$PRINT_TYPE = {
  PICK_LIST: 'printPickList',
  ABB: 'printAbb',
  ABBA4: 'printAbbA4',
  RDO: 'printRDO',
  INV: 'printINV',
  RCPT: 'printRCPT',
  COVER: 'printCover',
  TRD: 'printTRD',
  ORDER: 'printOrder',
  PO: 'printPO',
  ROG: 'printROG',
  IVM: 'printIVM',
  IVMRECV: 'printIVMRECV',
  RETOG: 'printRETOG'
}

new Vue({
  mixins: [dialog, globalMethod],
  router,
  store,
  vuetify,
  errorParser,
  render: h => h(App),
}).$mount('#app')
