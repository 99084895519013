<template>
    <div>
      <div class="d-flex align-center flex-column flex-sm-row">
        <v-pagination          
          :length="pagination.pageCount"
          v-model="options.page"
          :total-visible="9"
        ></v-pagination>
        <div class="text-body-1">ค้นหา {{ pagination.totalData }} จากทั้งหมด {{ pagination.totalRecords }} รายการ</div>
        <div class="d-flex align-center ml-sm-auto text-body-1">
          จำนวนแถวต่อหน้า 
          <v-select
            class="ml-4"
            style="max-width:75px;"
            hide-details            
            solo
            dense
            :items="[10,20,50,100,200]"
            v-model="options.itemsPerPage"            
          ></v-select>
        </div>
        <v-menu
          v-model="tableSheetUp"
          :close-on-content-click="false"
          :nudge-width="200"          
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn              
              v-bind="attrs"
              v-on="on"     
              icon                
            ><v-icon>{{ mdiTableCog }}</v-icon></v-btn>
          </template>

          <v-card class="pa-2">
            <v-checkbox 
                v-for="(header, header_index) in $attrs.headers" 
                :key="'header_' + header_index" 
                :label="header.text"
                :value="header"
                v-model="selectedHeaders"
              ></v-checkbox>
          </v-card>
        </v-menu>
      </div> 
      
      <v-data-table 
        v-bind="$attrs"
        :loading="isLoading"
        :items="data"        
        :headers="showHeaders"
        :options="options"                
        hide-default-footer         
        loading-text="กำลังโหลด... โปรดรอสักครู่"
        v-on="$listeners"
        :server-items-length="pagination.totalData"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>        
      </v-data-table>           

      <div class="d-flex align-center flex-column flex-sm-row">
        <v-pagination          
          :length="pagination.pageCount"
          v-model="options.page"
          :total-visible="9"
        ></v-pagination>
        <div class="text-body-1">ค้นหา {{ pagination.totalData }} จากทั้งหมด {{ pagination.totalRecords }} รายการ</div>
        <div class="d-flex align-center ml-sm-auto text-body-1">
          จำนวนแถวต่อหน้า 
          <v-select
            class="ml-4"
            style="max-width:75px;"
            hide-details            
            solo
            dense
            :items="[10,20,50,100,200]"
            v-model="options.itemsPerPage"            
          ></v-select>
        </div>
        <v-menu
          v-model="tableSheetDown"
          :close-on-content-click="false"
          :nudge-width="200"          
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn              
              v-bind="attrs"
              v-on="on"     
              icon                
            ><v-icon>{{ mdiTableCog }}</v-icon></v-btn>
          </template>

          <v-card class="pa-2">
            <v-checkbox 
                v-for="(header, header_index) in $attrs.headers" 
                :key="'header_' + header_index" 
                :label="header.text"
                :value="header"
                v-model="selectedHeaders"
              ></v-checkbox>
          </v-card>
        </v-menu>
      </div> 
    </div>    
</template>
<script>
import { asyncGet } from '@/helpers/asyncAxios'
import { mdiTableCog } from '@mdi/js'
import axios from 'axios'
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    queryParams: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isSaveState: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'default',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    options: {},
    isMaualSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      data: [],
      searchDelayTimer: null,
      pagination: {
        totalData: null,
        totalRecords: null,
        pageCount: null,
      },
      tableSheetUp: false,
      tableSheetDown: false,
      abortController: null,
      selectedHeaders: [],
      mdiTableCog,
    }
  },
  watch: {
    queryParams: {
      handler(value) {
        if (this.isMaualSearch) return

        if (this.searchDelayTimer) clearTimeout(this.searchDelayTimer)

        const newQueryParams = {}

        for (const [key, queryValue] of Object.entries(value)) {
          if (!!!queryValue) continue

          if (Array.isArray(queryValue)) {
            const newQuery = queryValue.map(item => item[this.itemValue])

            newQueryParams[key] = newQuery
          } else {
            newQueryParams[key] = queryValue
          }
        }
        this.searchDelayTimer = setTimeout(this.search, 250, { ...newQueryParams, ...this.options })
      },
      deep: true,
    },
    options: {
      handler(value) {
        if (this.searchDelayTimer) clearTimeout(this.searchDelayTimer)

        const newQueryParams = {}

        for (const [key, queryValue] of Object.entries(this.queryParams)) {
          if (!!!queryValue) continue

          if (Array.isArray(queryValue)) {
            const newQuery = queryValue.map(item => item[[this.itemValue]])

            newQueryParams[key] = newQuery
          } else {
            newQueryParams[key] = queryValue
          }
        }

        this.searchDelayTimer = setTimeout(this.search, 250, { ...value, ...newQueryParams })

        if (this.isSaveState) this.saveState()
      },
      deep: true,
    },
    /*
    'pagination.currentPage': function (value) {
      this.options.page = value
    },
    */
    selectedHeaders: {
      handler() {
        if (this.isSaveState) this.saveState()
      },
      deep: true,
    },
  },
  created() {
    if (!this.isSaveState) this.selectedHeaders = this.$attrs.headers
  },
  mounted() {
    if (this.isSaveState && !this.loadState()) {
      this.selectedHeaders = this.$attrs.headers
    }
  },
  computed: {
    showHeaders() {
      return this.$attrs.headers.filter(header => {
        for (let i = 0; i < this.selectedHeaders.length; i++) {
          const selectedHeader = this.selectedHeaders[i]

          if (selectedHeader.value == header.value) return true
        }
        return false
      })
    },
  },
  methods: {
    async search(searchInput) {
      if (this.isSaveState) this.saveState()

      if (this.abortController) {
        this.abortController.abort()
      }

      this.abortController = new AbortController()

      const root = this.$root

      this.isLoading = true
      try {
        const { data, pagination } = await asyncGet(this.url, searchInput, {
          signal: this.abortController.signal,
        })
        this.data = data
        this.pagination.totalData = pagination.totalData
        this.pagination.pageCount = pagination.pageCount
        this.pagination.totalRecords = pagination.totalRecords

        if (this.options.page > this.pagination.pageCount) this.options.page = 1

        this.isLoading = false
      } catch (error) {
        console.log(error)
        if (axios.isCancel(error)) {
          root.showCommonDialog('มีปัญหา', error)
          this.isLoading = false
        }
        //root.showCommonDialog('มีปัญหา', error)
      }
    },
    reload() {
      const newQueryParams = {}

      for (const [key, queryValue] of Object.entries(this.queryParams)) {
        if (!!!queryValue) continue

        if (Array.isArray(queryValue)) {
          const newQuery = queryValue.map(item => item[this.itemValue])

          newQueryParams[key] = newQuery
        } else {
          newQueryParams[key] = queryValue
        }
      }

      this.search({ ...this.options, ...newQueryParams })
    },
    saveState() {
      const state = {
        options: this.options,
        queryParams: this.queryParams,
        selectedHeaders: this.selectedHeaders,
        originalHeaders: this.$attrs.headers,
      }

      localStorage.setItem(
        'datatable.' + this.name + '.' + this.src + '.' + window.location.pathname,
        JSON.stringify(state),
      )
    },
    loadState() {
      if (!this.isSaveState) {
        localStorage.removeItem('datatable.' + this.name + '.' + this.src + '.' + window.location.pathname)
        return false
      }

      let item = localStorage.getItem('datatable.' + this.name + '.' + this.src + '.' + window.location.pathname)

      if (!item) return null

      const { options, queryParams, selectedHeaders, originalHeaders } = JSON.parse(item)

      if (!originalHeaders) return false

      if (!selectedHeaders || selectedHeaders.length == 0) return false

      if (originalHeaders.length != this.$attrs.headers.length) return false

      this.selectedHeaders = selectedHeaders

      this.$emit('loadState', { queryParams: queryParams, options: options })

      return true
    },
    updateSortBy(payload) {},
    updateSortDesc() {},
  },
}
</script>