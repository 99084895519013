export default [
    {
        path: '/c/:company_id/role',
        name: 'role',
        component: () => import('@/views/misc/employee/role/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Employee',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/role/:role_id',
        name: 'role_create',
        component: () => import('@/views/misc/employee/role/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Employee',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/member',
        name: 'member',
        component: () => import('@/views/misc/employee/member/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Employee',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/member/:admin_id',
        name: 'member_create',
        component: () => import('@/views/misc/employee/member/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Employee',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/invitation',
        name: 'invitation',
        component: () => import('@/views/misc/employee/invitation/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Employee',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/invitation/:invitation_id',
        name: 'invitation_create',
        component: () => import('@/views/misc/employee/invitation/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Employee',
            action: 'read'
        },
    },
]