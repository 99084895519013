<template>
    <div>
        <div style="display:flex;flex-direction:column;width:100%;padding: 5px;align-items:center;border-bottom: 1px solid black;">
            <div>
                <span for="" style="display:inline-flex;justify-content: flex-start;align-items:center;font-size: 1.5rem;font-weight:800;">{{ sortCodeArray[0] }}-</span>
                <span for="" style="display:inline-flex;font-size: 2rem;font-weight:800;justify-content: flex-end;align-items:center">{{ sortCodeArray[1] }}</span>
                <span for="" style="display:inline-flex;justify-content: flex-start;align-items:center;font-size: 1.5rem;font-weight:800;">-{{ sortCodeArray[2] }}</span>
            </div>
            <div class="barcode128">
                {{ $attrs.value.courierTrackingCode }}
            </div>
            <div class="barcode-text">
                {{ $attrs.value.courierTrackingCode }}
            </div>
        </div>    
        <div style="display:flex;width:100%;border-bottom: 1px solid black;padding: 5px;justify-content: center;align-items:center">
            {{ $attrs.value.sortingCode.dstCode }}
        </div>
        <div style="display: flex;border-bottom: 1px solid black;">
            <div style="display:flex;flex-direction:column;width:100%;padding: 10px;">
                <div>
                    <span style="font-weight:800;font-size:1.25rem">ผู้ส่ง : </span><span style="font-size:1.25rem">{{ $attrs.value.origin.name }} - {{  $attrs.value.origin.phone }}</span>
                </div>
                <div>
                    {{ $attrs.value.origin.address }} {{ $attrs.value.origin.subdistrict }} {{ $attrs.value.origin.district }} {{ $attrs.value.origin.province }} {{ $attrs.value.origin.postcode }}
                </div>
            </div>
            <div style="min-width: 100px;min-height: 100px;background-color: black;color: white;display: flex;justify-content: center;align-items:center;font-size: 2rem;font-weight:800">
                {{ $attrs.value.sortingCode.sortingLineCode }}
            </div>
        </div>        
        <div style="display: flex;border-bottom: 1px solid black;">
            <div style="display:flex;flex-direction:column;width:100%;padding: 10px;">
                <div>
                    <span style="font-weight:800;font-size:1.25rem">ผู้รับ : </span><span style="font-size:1.25rem">{{ $attrs.value.destination.name }} - {{  $attrs.value.destination.phone }}</span>
                </div>
                <div>
                    {{ $attrs.value.destination.address }} {{ $attrs.value.destination.subdistrict }} {{ $attrs.value.destination.district }} {{ $attrs.value.destination.province }} {{ $attrs.value.destination.postcode }}
                </div>
            </div>
            <div style="min-width: 100px;min-height: 100px;display: flex;justify-content: center;align-items:center;">
                <img :src="qrImage" alt="" style="max-width: 100%;">                              
            </div>
        </div>
        <div style="display: flex;border-bottom: 1px solid black;">
          <div style="border-right: 1px solid black; min-width: 150px;">
            <div style="padding: 10px;text-align: center;border-bottom: 1px solid black">
            <!--logo-->
              <img :src="$attrs.value.courierImage" alt="" style="max-width: 100%;max-height: 25px;">
            </div>
            <div style="text-align: center;">
              <!--COD-->
              {{ $attrs.value.isCod ? "COD : " + (formatNumber($attrs.value.codAmount)) + ' บาท' : "ไม่เก็บเงินปลายทาง" }}
            </div>
          </div>
          <div style="border-right: 1px solid black;display: flex;flex-direction: column; padding: 5px; flex-grow: 1;">
            <!--น้ำหนัก-->
            <div style="text-align: center;">
              น้ำหนัก
            </div>
            <div style="text-align: center;margin-top: auto;">
              {{ formatNumber($attrs.value.parcel.weight) }} กรัม
            </div>             
          </div>
          <div style="border-right: 1px solid black;display: flex;flex-direction: column;padding: 5px; flex-grow: 1;">
            <!--กว้าง-->
            <div style="text-align: center;">
              กว้าง
            </div>
            <div style="text-align: center;margin-top: auto;">
              {{ formatNumber($attrs.value.parcel.width) }} ซม.
            </div>             
          </div>
          <div style="border-right: 1px solid black;display: flex;flex-direction: column;padding: 5px; flex-grow: 1;">
            <!--กว้าง-->
            <div style="text-align: center;">
              ยาว
            </div>
            <div style="text-align: center;margin-top: auto;">
              {{ formatNumber($attrs.value.parcel.length) }} ซม.
            </div>             
          </div>
          <div style="border-right: 1px solid black;display: flex;flex-direction: column;padding: 5px; flex-grow: 1;">
            <!--สูง-->
            <div style="text-align: center;">
              สูง
            </div>
            <div style="text-align: center;margin-top: auto;">
              {{ formatNumber($attrs.value.parcel.height) }} ซม.
            </div>             
          </div>
        </div>
        <div style="word-break: break-all; padding: 5px; min-height: 50px;">
          <!-- Remark -->
           {{ $attrs.value.remark }}
        </div>
    </div>
</template>
<script>
import QRCode from 'qrcode'
export default {
  data() {
    return {
      qrImage: null,
    }
  },
  computed: {
    sortCodeArray() {
      return this.$attrs.value.sortingCode.sortCode.split('-')
    },
  },
  watch: {
    '$attrs.value': function () {
      this.generateQR()
    },
  },
  mounted() {
    this.generateQR()
  },
  methods: {
    generateQR() {
      const that = this
      QRCode.toDataURL(this.$attrs.value.courierTrackingCode, function (error, url) {
        if (error) console.error(error)

        that.qrImage = url
      })
    },
    formatNumber: function (number, tofixed = 2) {
      if (Number.isFinite(number) && tofixed > 0) number = number.toFixed(tofixed)

      if (!Number.isNaN(number)) return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      return 'ไม่สามารถประมวลผลได้'
    },
  },
}
</script>