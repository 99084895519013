export default [
    {
        path: '/c/:company_id/customer',
        name: 'customer',
        component: () => import('@/views/customer/list/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Customer',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/customer/:customer_id',
        name: 'customer_create',
        component: () => import('@/views/customer/list/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Customer',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/customertag',
        name: 'customertag',
        component: () => import('@/views/customer/tag/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Customer',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/customer/:customertag_id',
        name: 'customertag_create',
        component: () => import('@/views/customer/tag/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Customer',
            action: 'read'
        },
    },
]