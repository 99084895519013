<template>
    <v-hover>
        <template v-slot:default="{ hover }">
            <v-card v-bind="$attrs">
                <v-img
                    v-bind="$attrs"
                    v-on="$listeners"                    
                ></v-img>

                <v-overlay :value="overlay" @click="overlay = false" z-index="10">
                    <v-card>
                        <v-img
                            :src="$attrs.src"
                        ></v-img>
                    </v-card>            
                </v-overlay>

                <v-fade-transition>
                    <v-overlay
                        v-if="hover"
                        absolute
                        color="#036358"
                    >
                        <v-btn @click="overlay = true" icon large><v-icon>{{ icons.mdiArrowExpandAll }}</v-icon></v-btn>
                    </v-overlay>
                </v-fade-transition>
            </v-card>            
        </template>
    </v-hover>
</template>
<script>
import { mdiArrowExpandAll } from '@mdi/js'
export default {
  data() {
    return {
      overlay: false,
      icons: {
        mdiArrowExpandAll,
      },
    }
  },
}
</script>