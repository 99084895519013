export default [
    {
        path: '/companies',
        name: 'companies',
        component: () => import('@/views/CompanySelection.vue'),
        meta: {
            layout: 'withheader',
            resource: 'Auth',
            action: 'read'
        },
    },
    {
        path: '/companies/new',
        name: 'new-company',
        component: () => import('@/views/NewCompany.vue'),
        meta: {
            layout: 'withheader',
            resource: 'Auth',
            action: 'read'
        },
    },
]