import Vue from 'vue';

export const errorParser = (error) => {
    let errorMsg

    if (error.response) {
        const data = error.response.data

        const { message } = data

        if (message && Array.isArray(message)) {
            errorMsg = message.join(', ')
        } else {
            errorMsg = message
        }
    } else if (error.message) {
        errorMsg = error.message
    } else {
        errorMsg = error
    }

    return errorMsg
}

Vue.prototype.$errorParser = errorParser

export default errorParser