<template>
    <div class="d-flex px-6 mt-4 flex-column">
        <div class="d-flex flex-row">
            <v-avatar>
                <CompanyLogo
                    :src="$store.getters['app/GET_COMPANY_IMAGE']"
                    :value="$store.getters['app/GET_COMPANY_NAME']"                    
                    class="white--text"
                    size="50"
                />                
            </v-avatar>            
            <div class="px-4 align-center">
                <div style="font-size: 0.75rem">{{ $store.getters['app/GET_COMPANY_OWNERSHIP'] }}</div>
                <div>{{ $store.getters['app/GET_COMPANY_NAME'] }}</div>
            </div>
            <div class="ml-auto">
                <v-btn 
                icon
                @click="$router.push({ 'name' : 'companies' })"
                >
                    <v-icon>{{ mdiRotate3dVariant }}</v-icon>
                </v-btn>                
            </div>
        </div>
        <div class="text-caption mt-2">แพ็คเกจ : {{ $store.getters['app/GET_COMPANY_PACKAGE'] }}</div>
    </div>
</template>
<script>
import { mdiRotate3dVariant } from '@mdi/js'
export default {
  data() {
    return {
      mdiRotate3dVariant,
    }
  },
}
</script>