<template>
    <v-combobox 
        v-bind="$attrs"
        v-on="$listeners"
        :loading="isLoading"
        :search-input.sync="searchInput"  
        :items="data"
        @input="selectItem"
        @focus="triggerLazySearch"
        ref="VCombobox"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>   
    </v-combobox>
</template>
<script>
import { asyncGet } from '@/helpers/asyncAxios'
export default {
  name: 'DelayCombobox',
  props: {
    url: {
      type: String,
      required: true,
    },
    lazySearch: {
      type: Boolean,
      default: false,
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      searchInput: '',
      data: [],
      searchDelayTimer: null,
      abortController: null,
      firstSearch: false,
    }
  },
  watch: {
    searchInput(value) {
      if (this.lazySearch && !this.firstSearch) return

      if (this.searchDelayTimer) clearTimeout(this.searchDelayTimer)

      this.searchDelayTimer = setTimeout(this.search, 250, value)
    },
    '$attrs.value': {
      handler(newValue) {
        if (!newValue) {
          this.data = null
          return
        }

        if (!!!this.data) {
          // Single Select
          this.data = [newValue]
          return
        }

        if (this.data.length == 0 && newValue.length != 0) {
          // Multiple Select
          this.data = [...newValue]
        }
      },
      deep: true,
    },
  },
  methods: {
    async search(searchInput) {
      const root = this.$root

      if (this.abortController) {
        this.abortController.abort()
      }

      this.abortController = new AbortController()

      this.isLoading = true
      try {
        const response = await asyncGet(
          this.url,
          { search: searchInput },
          {
            signal: this.abortController.signal,
          },
        )
        this.data = response
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false

      if (!this.autoSelectFirst) return

      if (this.data.length == 0) return

      if (!!this.$attrs.value) return

      this.selectItem(this.data[0])
    },
    selectItem(value) {
      this.$emit('input', value)
    },
    triggerLazySearch() {
      if (this.lazySearch && !this.firstSearch) {
        this.firstSearch = true
        this.search('')
      }
    },
  },
}
</script>