<template>
    <img :src="currentDisplayImage" v-bind="$attrs">
</template>
<script>
import placeholderImage from '@/bulitInImages/noimage.svg'
export default {
  props: ['image'],
  data() {
    return {
      placeholderImage,
    }
  },
  methods: {
    setImage(image) {
      this.currentDisplayImage = image
    },
  },
  async created() {
    this.currentDisplayImage = this.placeholderImage

    if (this.image) {
      var img = new Image()

      img.onload = this.setImage(this.image)

      img.src = this.image
    }
  },
}
</script>
<style scoped>
img {
  max-height: 100px;
  max-width: 100px;
}
</style>