<template>
  <layout-blank>
    <div class="d-flex flex-column">
      <div class="d-flex align-center right-row py-2 px-4">
        <div class="d-flex align-center">
          <router-link
            to="/"
            class="d-flex align-center text-decoration-none"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
            <h2 class="app-title text--primary">
              {{ appName }}
            </h2>
          </router-link>
        </div>
        <div class="ml-auto">
          <app-bar-user-menu></app-bar-user-menu>
        </div>        
      </div>
      <div>
        <slot></slot>
      </div>
    </div>        
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import themeConfig from '@themeConfig'

export default {
  data() {
    return {
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
  components: {
    LayoutBlank,
    AppBarUserMenu,
  },
}
</script>

<style>
</style>
