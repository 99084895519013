const months = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
];

const days = [
    'อา.',
    'จ.',
    'อ.',
    'พ.',
    'พฤ.',
    'ศ.',
    'ส.',
]

export const dateObjectToStandardAooFormat = (( dateObject ) => {
    return dateObject.getDate().toString().padStart(2, "0") 
    + "/" + (dateObject.getMonth() * 1 + 1).toString().padStart(2, "0") 
    + "/" + dateObject.getFullYear() 
    + " " + dateObject.getHours().toString().padStart(2, "0")
    + ":" + dateObject.getMinutes().toString().padStart(2, "0")
    + ":" + dateObject.getSeconds().toString().padStart(2, "0")
})

export const timestampToStandardAooFormat = (timestamp => dateObjectToStandardAooFormat(new Date(timestamp)))

export const timestampToShortText = ((timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();    
    
    if (date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear())
        return date.getHours() + ':' + date.getMinutes().toString().padStart(2, '0') + ' น.';
    if (date.getFullYear() == today.getFullYear())
        return days[date.getDay()] + ' ' + date.getDate() + ' ' + months[date.getMonth()];
    return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
})

export const timestampToLongText = ((timestamp) => {
    const date = new Date(timestamp);    
    
    return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes().toString().padStart(2, '0') + ' น.';
})

export const dateObjectToDBString = (dateObject => {
    return dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1).toString().padStart(2 , 0) + '-' + dateObject.getDate().toString().padStart(2, 0)
})

export const monthArray = months
export const dateArray = days