import store from "../store"

export default {
    methods: {
        showCommonDialog(title, body) {
            store.state.app.commonDialog.title = title
            store.state.app.commonDialog.body = body
            store.state.app.commonDialog.isShowDialog = true
        }
    }
}