<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
    <notifications group="scannedResult" position="bottom right" />
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="$store.state.app.commonDialog.isShowDialog"
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
          class="text-h4"
        >{{ $store.state.app.commonDialog.title }}</v-toolbar>
        <v-card-text>
          <div class="text-h6 pa-12" v-html="$store.state.app.commonDialog.body"></div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            large
            @click="$store.state.app.commonDialog.isShowDialog = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="$store.state.app.confirmDialog.isShowDialog"
      persistent
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
          class="text-h4"
        >{{ $store.state.app.confirmDialog.title }}</v-toolbar>
        <v-card-text>
          <div class="text-h6 pa-12" v-html="$store.state.app.confirmDialog.body"></div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-for="(btn, btn_index) in $store.state.app.confirmDialog.buttons"
            :key="btn_index"
            large
            :color="btn.color"
            :text="btn.color == 'secondary'"
            @click="callbackConfirmDialogButton(btn.result)"
          >{{ btn.text }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog 
      persistent
      v-model="$store.state.app.globalLoading"
      max-width="600"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          <h1>โปรดรอสักครู่</h1>          
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>      
    </v-dialog >
    <notifications group="addProduct" position="bottom right" />
    <notifications group="misc" position="bottom left" />

    <div
    id="printArea"    
    class="printArea"    
    >
        <order 
        v-if="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.ORDER && !$store.getters['app/GET_PRINT_FLAG']"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></order>

        <picklist 
        v-if="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.PICK_LIST && !$store.getters['app/GET_PRINT_FLAG']"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></picklist>

        <cover 
        v-if="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.COVER && !$store.getters['app/GET_PRINT_FLAG']"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></cover>

        <abb 
        v-if="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.ABB && !$store.getters['app/GET_PRINT_FLAG']"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></abb>

        <abbA4 
        v-if="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.ABBA4 && !$store.getters['app/GET_PRINT_FLAG']"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></abbA4>

        <po
        v-if="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.PO && !$store.getters['app/GET_PRINT_FLAG']"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></po>

        <rog
        v-if="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.ROG && !$store.getters['app/GET_PRINT_FLAG']"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></rog>

        <ivm
        v-if="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.IVM && !$store.getters['app/GET_PRINT_FLAG']"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></ivm>

        <ivmrecv
        v-if="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.IVMRECV && !$store.getters['app/GET_PRINT_FLAG']"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></ivmrecv>

        <retog
        v-if="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.RETOG && !$store.getters['app/GET_PRINT_FLAG']"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></retog>

        <invrcpt
        v-if="($store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.INV || $store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.RCPT)
        && !$store.getters['app/GET_PRINT_FLAG']"
        :type="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.INV ? 'inv' : 'rcpt'"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></invrcpt>

        <trd
        v-if="$store.getters['app/GET_PRINT_TYPE'] == $PRINT_TYPE.TRD && !$store.getters['app/GET_PRINT_FLAG']"
        :printData="$store.getters['app/GET_PRINT_DATA']" 
        :company="$store.getters['app/GET_PRINT_COMPANY']"></trd>
    </div>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentVerticalNavFullWidth from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNavFullWidth.vue'
import LayoutWithHeader from '@/layouts/variants/withheader/LayoutWithHeader.vue'
import abb from './components/print/abb.vue'
import abbA4 from './components/print/abbA4.vue'
import invrcpt from './components/print/invrcpt.vue'
import ivm from './components/print/ivm.vue'
import ivmrecv from './components/print/ivmrecv.vue'
import order from './components/print/order.vue'
import picklist from './components/print/picklist.vue'
import po from './components/print/po.vue'
import retog from './components/print/retog.vue'
import rog from './components/print/rog.vue'
import cover from './components/print/sendcover.vue'
import trd from './components/print/trd.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutContentVerticalNavFullWidth,
    LayoutBlank,
    LayoutWithHeader,
    picklist,
    cover,
    abb,
    po,
    abbA4,
    invrcpt,
    rog,
    order,
    ivm,
    ivmrecv,
    retog,
    trd,
  },
  setup(props, context) {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { root } = context

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`
      if (route.value.meta.layout === 'content-fullwidth')
        return `layout-content-${appContentLayoutNav.value}-nav-full-width`
      if (route.value.meta.layout === 'withheader') return 'layout-with-header'

      return null
    })

    useDynamicVh()

    window.fbAsyncInit = function () {
      FB.init({
        appId: process.env.VUE_APP_FB_ID,
        version: 'v19.0',
      })
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')

    const callbackConfirmDialogButton = value => {
      const { root } = context
      root.$store.commit('app/SET_CONFIRM_DIALOG_RESULT', value)
    }

    watch(
      () => root.$store.getters['app/GET_PRINT_DATA'],
      function (value) {
        if (!root.$store.getters['app/GET_PRINT_FLAG']) checkImgLoaded()
      },
    )

    const checkImgLoaded = () => {
      const { root } = context

      root.$store.commit('app/SET_GLOBAL_LOADING', true)

      const printArea = document.getElementById('printArea')

      let images = printArea.querySelectorAll('img')
      let isAllLoaded = true

      images.forEach(element => {
        if (!element.complete) {
          isAllLoaded = false
        }
      })

      if (isAllLoaded) {
        root.$nextTick().then(() => {
          root.$htmlToPaper('printArea', {
            autoClose: false,
            styles: ['/styles/print.css'],
          })

          root.$store.commit('app/SET_GLOBAL_LOADING', false)
          root.$store.commit('app/SET_PRINT_FLAG', true)
        })
      } else {
        setTimeout(function () {
          checkImgLoaded()
        }, 200)
      }
    }

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      callbackConfirmDialogButton,
    }
  },
}
</script>
<style>
@import '~vue-file-agent/dist/vue-file-agent.css';

.printArea {
  display: none;
}
</style>