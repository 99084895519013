export default [
    {
        path: '/c/:company_id/pos/',
        name: 'pos',
        component: () => import('@/views/order/pos/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Auth',
            action: 'read'                        
        },
    },
    {
        path: '/c/:company_id/pos/:device_id',
        name: 'posorder',
        component: () => import('@/views/order/pos/order.vue'),
        meta: {
            layout: 'content',  
            resource: 'Auth',
            action: 'read'                            
        },
    },
    {
        path: '/c/:company_id/pos/:device_id/order/:posorder_id',
        name: 'posorder_create',
        component: () => import('@/views/order/pos/create.vue'),
        meta: {
            layout: 'content',  
            resource: 'Auth',
            action: 'read'                            
        },
    },
]