export default [
    {
        path: '/c/:company_id/stock',
        name: 'stock',
        component: () => import('@/views/inventory/stock/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },        
    {
        path: '/c/:company_id/stock/import',
        name: 'stock_import',
        component: () => import('@/views/inventory/stock/import.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },    
    {
        path: '/c/:company_id/stock/export',
        name: 'stock_export',
        component: () => import('@/views/inventory/stock/export.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/stock/:product_id',
        name: 'stock_view',
        component: () => import('@/views/inventory/stock/view.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/stock/:product_id/variations',
        name: 'stock_view_variation',
        component: () => import('@/views/inventory/stock/viewvariation.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/stock/:stock_id/history',
        name: 'stock_history',
        component: () => import('@/views/inventory/stock/history.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    }, 
    {
        path: '/c/:company_id/stock/:stock_id/reserved',
        name: 'stock_reserved',
        component: () => import('@/views/inventory/stock/reserved.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/warehouse',
        name: 'warehouse',
        component: () => import('@/views/inventory/warehouse/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },    
    {
        path: '/c/:company_id/warehouse/:warehouse_id',
        name: 'warehouse_create',
        component: () => import('@/views/inventory/warehouse/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/ivm',
        name: 'ivm',
        component: () => import('@/views/inventory/ivm/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },    
    {
        path: '/c/:company_id/ivm/:ivm_id',
        name: 'ivm_create',
        component: () => import('@/views/inventory/ivm/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/ivm/:ivm_id/recv',
        name: 'ivm_recv',
        component: () => import('@/views/inventory/ivm/recv.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'update'
        },
    },
    {
        path: '/c/:company_id/retog',
        name: 'retog',
        component: () => import('@/views/inventory/retog/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },   
    {
        path: '/c/:company_id/retog/:retog_id',
        name: 'retog_create',
        component: () => import('@/views/inventory/retog/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/shareablelink',
        name: 'shareablelink',
        component: () => import('@/views/inventory/shareablelink/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },   
    {
        path: '/c/:company_id/shareablelink/:shareablelink_id',
        name: 'shareablelink_create',
        component: () => import('@/views/inventory/shareablelink/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Inventory',
            action: 'read'
        },
    },
]