export default [    
    {
        path: '/c/:company_id/pospayment',
        name: 'pospayment',
        component: () => import('@/views/misc/possetting/payment/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'POSSetting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/pospayment/:pospayment_id',
        name: 'pospayment_create',
        component: () => import('@/views/misc/possetting/payment/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'POSSetting',
            action: 'read'
        },
    },    
    {
        path: '/c/:company_id/device',
        name: 'device',
        component: () => import('@/views/misc/possetting/device/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'POSSetting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/device/:device_id',
        name: 'device_create',
        component: () => import('@/views/misc/possetting/device/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'POSSetting',
            action: 'read'
        },
    }, 
]