<template>
    <div>
        <div v-for="(trd, page_index) in printData" :key="'page_' + page_index">
            <div style="
            width: 100%;
            justify-content: center;
            page-break-after: always;
            box-sizing: border-box;
            position: relative;
            padding: 10px;
            "
            >
                <table style="width: 100%; border-spacing: 0">
                <thead>
                    <tr>
                        <td style="width: 10%"></td>
                        <td style="width: 40%"></td>
                        <td style="width: 50%"></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="vertical-align: top">
                            <img
                            style="
                                object-fit: contain;
                                max-height: 100px;
                                max-width: 100px;
                            "
                            :src="trd.companyProfile"
                            alt=""
                            />
                        </td>
                        <td style="vertical-align: top">
                            <div>{{ trd.companyName }}</div>
                            <div style="margin-top: 5px">{{ trd.branch }}</div>
                            <div style="margin-top: 5px">{{ trd.headerAddress }}</div>
                            <div style="margin-top: 5px" v-if="trd.companyTel">
                            เบอร์ : {{ trd.companyTel }}
                            </div>
                            <div style="margin-top: 5px">
                            เลขประจำตัวผู้เสียภาษี : {{ trd.headerTaxID }}
                            </div>
                        </td>
                        <td
                            rowspan="2"
                            style="width: 50%; vertical-align: top; padding-right: 15px"
                        >
                            <div
                            style="
                                font-size: 1.3em;
                                font-weight: bold;
                                text-align: right;
                                padding-bottom: 15px;
                            "
                            >
                            ใบกำกับภาษี/ใบเสร็จรับเงิน/ใบส่งของ
                            </div>
                            <div
                            style="
                                font-size: 1.325em;
                                text-align: right;
                                border-bottom: 1px solid black;
                                padding-bottom: 15px;
                            "
                            >
                            ต้นฉบับ (เอกสารออกเป็นชุด)
                            </div>
                            <div>
                            <table style="width: 100%">
                                <thead>
                                <tr>
                                    <td style="width: 50%"></td>
                                    <td style="width: 50%"></td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style="padding-left: 20%; padding-top: 5px">
                                    เลขที่
                                    </td>
                                    <td style="padding-top: 5px">{{ trd.trdNumber }}</td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 20%; padding-top: 5px">
                                    วันที่
                                    </td>
                                    <td style="padding-top: 5px">{{ trd.createdAt }}</td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 20%; padding-top: 5px">
                                    ครบกำหนด
                                    </td>
                                    <td style="padding-top: 5px">{{ trd.createdAt }}</td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 20%; padding-top: 5px">
                                    พนักงานขาย
                                    </td>
                                    <td style="padding-top: 5px">
                                    {{ trd.seller }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 20%; padding-top: 5px">
                                    อ้างอิง
                                    </td>
                                    <td style="padding-top: 5px">{{ trd.orderNumner }}</td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 20%; padding-top: 5px">
                                    อ้างอิงแบบย่อ
                                    </td>
                                    <td style="padding-top: 5px">{{ trd.abbNumber }}</td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div style="font-weight: bold">ลูกค้า</div>
                            <div style="margin-top: 5px">
                            {{ trd.customerName }}
                            </div>
                            <div style="margin-top: 5px">
                            {{ trd.customerAddress }}
                            </div>
                            <div style="margin-top: 5px" v-if="trd.customerTel">
                            เบอร์ : {{ trd.customerTel }}
                            </div>
                            <div style="margin-top: 5px">
                            เลขประจำตัวผู้เสียภาษี : {{ trd.customerTaxID }}
                            </div>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
                </table>
                <table
                style="
                    width: 100%;
                    border-spacing: 0;
                    margin-top: 15px;
                    padding-bottom: 15px;
                    min-height: 250px;
                "
                >
                <thead>
                    <tr>
                        <td
                            style="
                            border-top: 1px solid black;
                            border-bottom: 1px solid black;
                            width: 5%;
                            height: 60px;
                            "
                        >
                            #
                        </td>
                        <td
                            style="
                            border-top: 1px solid black;
                            border-bottom: 1px solid black;
                            width: 30%;
                            height: 60px;
                            "
                        >
                            รายการสินค้า
                        </td>
                        <td
                            style="
                            border-top: 1px solid black;
                            border-bottom: 1px solid black;
                            width: 10%;
                            height: 60px;
                            text-align: center;
                            "
                        >
                            จำนวน
                        </td>
                        <td
                            style="
                            border-top: 1px solid black;
                            border-bottom: 1px solid black;
                            width: 15%;
                            height: 60px;
                            text-align: center;
                            "
                        >
                            <div>ราคาต่อหน่วย</div>
                            <div style="font-size: 0.825em">รวมภาษีมูลค่าเพิ่ม</div>
                        </td>
                        <td
                            style="
                            border-top: 1px solid black;
                            border-bottom: 1px solid black;
                            width: 10%;
                            height: 60px;
                            text-align: center;
                            "
                        >
                            ส่วนลด
                        </td>
                        <td
                            style="
                            border-top: 1px solid black;
                            border-bottom: 1px solid black;
                            width: 15%;
                            height: 60px;
                            text-align: center;
                            "
                        >
                            <div>ราคารวม</div>
                            <div style="font-size: 0.825em">หลังหักส่วนลด</div>
                        </td>
                        <td
                            style="
                            border-top: 1px solid black;
                            border-bottom: 1px solid black;
                            width: 15%;
                            height: 60px;
                            text-align: center;
                            "
                        >
                            <div>VAT</div>
                            <div style="font-size: 0.825em">ภาษีมูลค่าเพิ่ม</div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    style="vertical-align: top"
                    height="30px"
                    v-for="(product, product_index) in trd.order.orderItems"
                    :key="'product_' + product_index"
                    >
                        <td style="padding-top: 15px">{{ product_index + 1 }}</td>
                        <td style="padding-top: 15px">
                            <div>{{ product.name }}</div>
                            <div style="opacity: 0.8; font-size: 0.975em">
                            {{ product.sku }}
                            </div>
                        </td>
                        <td style="text-align: right; padding-top: 15px">
                            {{ product.quantity }}
                            {{ product.unit }}
                        </td>
                        <td style="text-align: right; padding-top: 15px">
                            {{ product.price | formatNumber }}
                        </td>
                        <td style="text-align: right; padding-top: 15px">
                            {{ (product.discount ? product.discount * 1 : 0) | formatNumber }} {{ product.discount_type == 'percent' ? '%' : '' }}
                        </td>
                        <td style="text-align: right; padding-top: 15px">
                            {{ product.priceSum | formatNumber }}
                        </td>
                        <td style="text-align: right; padding-top: 15px">
                            {{ (product.is_vat ? product.VATInValue : 0) | formatNumber }}
                        </td>
                    </tr>
                    <tr style="height: 100%"></tr>
                </tbody>
                </table>
                <div style="page-break-inside: avoid">
                <table style="width: 100%; border-spacing: 0">
                    <thead>
                    <tr>
                        <td style="width: 50%; border-top: 1px solid black"></td>
                        <td style="width: 30%; border-top: 1px solid black"></td>
                        <td style="width: 20%; border-top: 1px solid black"></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td></td>
                        <td style="text-align: right; padding-top: 15px">
                        รวมเป็นเงิน
                        </td>
                        <td style="text-align: right; padding-top: 15px">
                        {{ trd.order.priceBeforeDiscount | formatNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right; padding-top: 15px">ส่วนลด</td>
                        <td style="text-align: right; padding-top: 15px">
                        {{ (trd.order.discount ? trd.order.discount * 1 : 0) | formatNumber }} {{ (trd.order.discount_type == 'percent' ? '%' : '') }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right; padding-top: 15px">
                        จำนวนเงินหลังหักส่วนลด
                        </td>
                        <td style="text-align: right; padding-top: 15px">
                        {{ trd.order.priceAfterDiscount | formatNumber }}
                        </td>
                    </tr>
                    <tr v-if="trd.order.delivery_cost">
                        <td></td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                        "
                        >
                        ค่าจัดส่ง
                        </td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                        "
                        >
                        {{ (trd.order.delivery_cost * 1) | formatNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right; padding-top: 15px">
                        ภาษีมูลค่าเพิ่ม 7.0%
                        </td>
                        <td style="text-align: right; padding-top: 15px">
                        {{ trd.order.VATSum | formatNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                        "
                        >
                        ราคาก่อนภาษีมูลค่าเพิ่ม
                        </td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                        "
                        >
                        {{ trd.order.priceBeforeVAT | formatNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td>({{ trd.order.totalPrice | number2Text }})</td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                            font-size: 1.25em;
                            font-weight: bold;
                        "
                        >
                        (รวมภาษีมูลค่าเพิ่ม) รวม
                        </td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                            font-size: 1.25em;
                            font-weight: bold;
                        "
                        >
                        {{  trd.order.totalPrice | formatNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td
                        style="
                            text-align: right;
                            border-bottom: 3px solid black;
                            font-size: 1.25em;
                            font-weight: bold;
                        "
                        ></td>
                        <td
                        style="
                            text-align: right;
                            border-bottom: 3px solid black;
                            font-size: 1.25em;
                            font-weight: bold;
                        "
                        ></td>
                    </tr>
                    </tbody>
                </table>
                <table style="width: 100%; border-spacing: 0; margin-top: 45px">
                    <thead>
                    <tr>
                        <td style="width: 50%"></td>
                        <td style="width: 50%"></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>หมายเหตุ</td>
                        <td>ชำระโดย</td>
                    </tr>
                    <tr>
                        <td style="padding-top: 15px">
                        {{ trd.order.note }}
                        </td>
                        <td style="padding-top: 15px">
                        {{ trd.order.payment }}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table style="width: 100%; margin-top: 45px; border-spacing: 30px 0">
                    <thead>
                    <tr>
                        <td style="width: 35%; border-bottom: 0.5px dotted black"></td>
                        <td style="width: 15%; border-bottom: 0.5px dotted black"></td>
                        <td style="width: 35%; border-bottom: 0.5px dotted black"></td>
                        <td style="width: 15%; border-bottom: 0.5px dotted black"></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style="text-align: center; padding-top: 15px">
                        ผู้รับสินค้า
                        </td>
                        <td style="text-align: center; padding-top: 15px">วันที่</td>
                        <td style="text-align: center; padding-top: 15px">
                        ผู้รับเงิน
                        </td>
                        <td style="text-align: center; padding-top: 15px">วันที่</td>
                    </tr>
                    </tbody>
                </table>
                </div>
                <div
                v-if="trd.status == 'cancelled'"
                style="
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                    font-size: 2.5em;
                    border-style: solid;
                    border-color: red;
                    padding: 10px;
                    color: red;
                "
                >
                ยกเลิก
                </div>
            </div>
            <div style="
            width: 100%;
            justify-content: center;
            page-break-after: always;
            box-sizing: border-box;
            position: relative;
            padding: 10px;
            "
            >
                <table style="width: 100%; border-spacing: 0">
                <thead>
                    <tr>
                    <td style="width: 10%"></td>
                    <td style="width: 40%"></td>
                    <td style="width: 50%"></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td style="vertical-align: top">
                        <img
                        style="
                            object-fit: contain;
                            max-height: 100px;
                            max-width: 100px;
                        "
                        :src="trd.companyProfile"
                        alt=""
                        />
                    </td>
                    <td style="vertical-align: top">
                        <div>{{ trd.companyName }}</div>
                        <div style="margin-top: 5px">{{ trd.branch }}</div>
                        <div style="margin-top: 5px">{{ trd.headerAddress }}</div>
                        <div style="margin-top: 5px" v-if="trd.companyTel">
                        เบอร์ : {{ trd.companyTel }}
                        </div>
                        <div style="margin-top: 5px">
                        เลขประจำตัวผู้เสียภาษี : {{ trd.headerTaxID }}
                        </div>
                    </td>
                    <td
                        rowspan="2"
                        style="width: 50%; vertical-align: top; padding-right: 15px"
                    >
                        <div
                        style="
                            font-size: 1.3em;
                            font-weight: bold;
                            text-align: right;
                            padding-bottom: 15px;
                        "
                        >
                        ใบกำกับภาษี/ใบเสร็จรับเงิน/ใบส่งของ
                        </div>
                        <div
                        style="
                            font-size: 1.325em;
                            text-align: right;
                            border-bottom: 1px solid black;
                            padding-bottom: 15px;
                        "
                        >
                        สำเนา (เอกสารออกเป็นชุด)
                        </div>
                        <div>
                        <table style="width: 100%">
                            <thead>
                            <tr>
                                <td style="width: 50%"></td>
                                <td style="width: 50%"></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td style="padding-left: 20%; padding-top: 5px">
                                เลขที่
                                </td>
                                <td style="padding-top: 5px">{{ trd.trdNumber }}</td>
                            </tr>
                            <tr>
                                <td style="padding-left: 20%; padding-top: 5px">
                                วันที่
                                </td>
                                <td style="padding-top: 5px">{{ trd.createdAt }}</td>
                            </tr>
                            <tr>
                                <td style="padding-left: 20%; padding-top: 5px">
                                ครบกำหนด
                                </td>
                                <td style="padding-top: 5px">{{ trd.createdAt }}</td>
                            </tr>
                            <tr>
                                <td style="padding-left: 20%; padding-top: 5px">
                                พนักงานขาย
                                </td>
                                <td style="padding-top: 5px">
                                {{ trd.seller }}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding-left: 20%; padding-top: 5px">
                                อ้างอิง
                                </td>
                                <td style="padding-top: 5px">{{ trd.orderNumner }}</td>
                            </tr>
                            <tr>
                                <td style="padding-left: 20%; padding-top: 5px">
                                อ้างอิงแบบย่อ
                                </td>
                                <td style="padding-top: 5px">{{ trd.abbNumber }}</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td colspan="2">
                        <div style="font-weight: bold">ลูกค้า</div>
                        <div style="margin-top: 5px">
                        {{ trd.customerName }}
                        </div>
                        <div style="margin-top: 5px">
                        {{ trd.customerAddress }}
                        </div>
                        <div style="margin-top: 5px" v-if="trd.customerTel">
                        เบอร์ : {{ trd.customerTel }}
                        </div>
                        <div style="margin-top: 5px">
                        เลขประจำตัวผู้เสียภาษี : {{ trd.customerTaxID }}
                        </div>
                    </td>
                    <td></td>
                    </tr>
                </tbody>
                </table>
                <table
                style="
                    width: 100%;
                    border-spacing: 0;
                    margin-top: 15px;
                    padding-bottom: 15px;
                    min-height: 250px;
                "
                >
                <thead>
                    <tr>
                    <td
                        style="
                        border-top: 1px solid black;
                        border-bottom: 1px solid black;
                        width: 5%;
                        height: 60px;
                        "
                    >
                        #
                    </td>
                    <td
                        style="
                        border-top: 1px solid black;
                        border-bottom: 1px solid black;
                        width: 30%;
                        height: 60px;
                        "
                    >
                        รายการสินค้า
                    </td>
                    <td
                        style="
                        border-top: 1px solid black;
                        border-bottom: 1px solid black;
                        width: 10%;
                        height: 60px;
                        text-align: center;
                        "
                    >
                        จำนวน
                    </td>
                    <td
                        style="
                        border-top: 1px solid black;
                        border-bottom: 1px solid black;
                        width: 15%;
                        height: 60px;
                        text-align: center;
                        "
                    >
                        <div>ราคาต่อหน่วย</div>
                        <div style="font-size: 0.825em">รวมภาษีมูลค่าเพิ่ม</div>
                    </td>
                    <td
                        style="
                        border-top: 1px solid black;
                        border-bottom: 1px solid black;
                        width: 10%;
                        height: 60px;
                        text-align: center;
                        "
                    >
                        ส่วนลด
                    </td>
                    <td
                        style="
                        border-top: 1px solid black;
                        border-bottom: 1px solid black;
                        width: 15%;
                        height: 60px;
                        text-align: center;
                        "
                    >
                        <div>ราคารวม</div>
                        <div style="font-size: 0.825em">หลังหักส่วนลด</div>
                    </td>
                    <td
                        style="
                        border-top: 1px solid black;
                        border-bottom: 1px solid black;
                        width: 15%;
                        height: 60px;
                        text-align: center;
                        "
                    >
                        <div>VAT</div>
                        <div style="font-size: 0.825em">ภาษีมูลค่าเพิ่ม</div>
                    </td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    style="vertical-align: top"
                    height="30px"
                    v-for="(product, product_index) in trd.order.orderItems"
                    :key="'product_' + product_index"
                    >
                    <td style="padding-top: 15px">{{ product_index + 1 }}</td>
                    <td style="padding-top: 15px">
                        <div>{{ product.name }}</div>
                        <div style="opacity: 0.8; font-size: 0.975em">
                        {{ product.sku }}
                        </div>
                    </td>
                    <td style="text-align: right; padding-top: 15px">
                        {{ product.quantity }}
                        {{ product.unit }}
                    </td>
                    <td style="text-align: right; padding-top: 15px">
                        {{ (product.price * 1) | formatNumber }}
                    </td>
                    <td style="text-align: right; padding-top: 15px">
                        {{ (product.discount ? product.discount * 1 : 0) | formatNumber }} {{ product.discount_type == 'percent' ? '%' : '' }}
                    </td>
                    <td style="text-align: right; padding-top: 15px">
                        {{ product.priceSum | formatNumber }}
                    </td>
                    <td style="text-align: right; padding-top: 15px">
                        {{ (product.is_vat ? product.VATInValue : 0) | formatNumber }}
                    </td>
                    </tr>
                    <tr style="height: 100%"></tr>
                </tbody>
                </table>
                <div style="page-break-inside: avoid">
                <table style="width: 100%; border-spacing: 0">
                    <thead>
                    <tr>
                        <td style="width: 50%; border-top: 1px solid black"></td>
                        <td style="width: 30%; border-top: 1px solid black"></td>
                        <td style="width: 20%; border-top: 1px solid black"></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td></td>
                        <td style="text-align: right; padding-top: 15px">
                        รวมเป็นเงิน
                        </td>
                        <td style="text-align: right; padding-top: 15px">
                        {{ trd.order.priceBeforeDiscount | formatNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right; padding-top: 15px">ส่วนลด</td>
                        <td style="text-align: right; padding-top: 15px">
                        {{ (trd.order.discount ? trd.order.discount * 1 : 0) | formatNumber }} {{ (trd.order.discount_type == 'percent' ? '%' : '') }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right; padding-top: 15px">
                        จำนวนเงินหลังหักส่วนลด
                        </td>
                        <td style="text-align: right; padding-top: 15px">
                        {{ trd.order.priceAfterDiscount | formatNumber }}
                        </td>
                    </tr>
                    <tr v-if="trd.order.delivery_cost">
                        <td></td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                        "
                        >
                        ค่าจัดส่ง
                        </td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                        "
                        >
                        {{ (trd.order.delivery_cost * 1) | formatNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right; padding-top: 15px">
                        ภาษีมูลค่าเพิ่ม 7.0%
                        </td>
                        <td style="text-align: right; padding-top: 15px">
                        {{ trd.order.VATSum | formatNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                        "
                        >
                        ราคาก่อนภาษีมูลค่าเพิ่ม
                        </td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                        "
                        >
                        {{ trd.order.priceBeforeVAT | formatNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td>({{ trd.order.totalPrice | number2Text }})</td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                            font-size: 1.25em;
                            font-weight: bold;
                        "
                        >
                        (รวมภาษีมูลค่าเพิ่ม) รวม
                        </td>
                        <td
                        style="
                            text-align: right;
                            padding-top: 15px;
                            padding-bottom: 15px;
                            border-bottom: 1px solid black;
                            font-size: 1.25em;
                            font-weight: bold;
                        "
                        >
                        {{  trd.order.totalPrice | formatNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td
                        style="
                            text-align: right;
                            border-bottom: 3px solid black;
                            font-size: 1.25em;
                            font-weight: bold;
                        "
                        ></td>
                        <td
                        style="
                            text-align: right;
                            border-bottom: 3px solid black;
                            font-size: 1.25em;
                            font-weight: bold;
                        "
                        ></td>
                    </tr>
                    </tbody>
                </table>
                <table style="width: 100%; border-spacing: 0; margin-top: 45px">
                    <thead>
                    <tr>
                        <td style="width: 50%"></td>
                        <td style="width: 50%"></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>หมายเหตุ</td>
                        <td>ชำระโดย</td>
                    </tr>
                    <tr>
                        <td style="padding-top: 15px">
                        {{ trd.order.note }}
                        </td>
                        <td style="padding-top: 15px">
                        {{ trd.order.payment }}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table style="width: 100%; margin-top: 45px; border-spacing: 30px 0">
                    <thead>
                    <tr>
                        <td style="width: 35%; border-bottom: 0.5px dotted black"></td>
                        <td style="width: 15%; border-bottom: 0.5px dotted black"></td>
                        <td style="width: 35%; border-bottom: 0.5px dotted black"></td>
                        <td style="width: 15%; border-bottom: 0.5px dotted black"></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style="text-align: center; padding-top: 15px">
                        ผู้รับสินค้า
                        </td>
                        <td style="text-align: center; padding-top: 15px">วันที่</td>
                        <td style="text-align: center; padding-top: 15px">
                        ผู้รับเงิน
                        </td>
                        <td style="text-align: center; padding-top: 15px">วันที่</td>
                    </tr>
                    </tbody>
                </table>
                </div>
                <div
                v-if="trd.status == 'cancelled'"
                style="
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                    font-size: 2.5em;
                    border-style: solid;
                    border-color: red;
                    padding: 10px;
                    color: red;
                "
                >
                ยกเลิก
                </div>
            </div>
        </div>        
    </div>    
</template>
<script>
import { Order } from '@/classes/order/Order'
export default {
  props: {
    printData: {
      required: true,
      default: [],
    },
  },
  data() {
    return {
      Order: Order,
    }
  },
  methods: {
    isSaleOrder(order) {
      return order instanceof Order
    },
  },
}
</script>