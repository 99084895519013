<template>
    <v-avatar 
    :color="!!$attrs.src ? '' : '#FE1717'" 
    v-bind="$attrs" 
    :image="$attrs.src">
      <v-img
        v-if="$attrs.src"
        alt="Avatar"
        :src="$attrs.src"
      />
      <div v-else>{{ companyName }}</div>
    </v-avatar>    
</template>
<script>
export default {
  computed: {
    companyName() {
      if (!this.$attrs.value) return ''

      let ret = ''

      ret += this.$attrs.value[0]

      if (this.$attrs.value.length > 1) ret += this.$attrs.value[1]

      return ret.toLocaleUpperCase()
    },
  },
}
</script>