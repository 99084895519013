import { io } from 'socket.io/client-dist/socket.io.min.js';

export default {
    namespaced: true,
    state: {
        socketInstance: null,
        chat: {
            unreadCount: 0,
            latestEvent: null,
            notificationAudio: new Audio(require('@/assets/audio/messenger.mp3'))
        }
    },
    getters: {
        GET_LATEST_CHAT_EVENT(state) {
            return state.chat.latestEvent
        },
        GET_CHAT_UNREAD_COUNT(state) {
            return state.chat.unreadCount
        }
    },
    mutations: {
        SET_SOCKET_INSTANCE(state, payload) {
            if (state.socketInstance) {
                state.chat.latestEvent = null                
                state.socketInstance.close();
            }
            state.socketInstance = payload;
        },
        SET_LATEST_CHAT_EVENT(state, payload) {
            state.chat.latestEvent = payload            
        },
        CLEAR_LATEST_CHAT_EVENT(state) {
            state.chat.latestEvent = null
        },
        INC_CHAT_UNREAD(state) {
            state.chat.unreadCount++
        },
        DEC_CHAT_UNREAD(state) {
            state.chat.unreadCount--

            if (state.chat.unreadCount < 0)
                state.chat.unreadCount = 0
        },
        READ_CHAT(state, payload) {
            state.chat.unreadCount -= payload

            if (state.chat.unreadCount < 0)
                state.chat.unreadCount = 0
        }
    },
    actions: {
        async INIT({dispatch, commit , state}, {url, companyId, wsToken}) {
            await commit('SET_SOCKET_INSTANCE', io(url, { extraHeaders: {
                'Authorization': 'Bearer ' + wsToken,
                'Company': companyId
            }}));

            state.socketInstance.on('MESSAGE_EVENT', e => dispatch('PROCESS_MESSAGE_EVENT', e));
            state.socketInstance.on('MESSAGE_UNSENT_EVENT', e => dispatch('PROCESS_MESSAGE_UNSENT_EVENT', e));
            state.socketInstance.on('NOTIFICATION', e => dispatch('PROCESS_NOTIFICATION', e));
        },
        PROCESS_MESSAGE_EVENT({dispatch, commit , state, rootGetters}, rawMessage) {
            /*
            if (!rawMessage.message.is_echo) {
                commit('INC_CHAT_UNREAD');
            } else {
                state.chat.notificationAudio.play()
            } 
            */
           
            const isMuted = rootGetters['app/GET_MUTED_STATE'];
                
            if (!isMuted && !rawMessage.message.message.is_echo)
                state.chat.notificationAudio.play()

            commit('INC_CHAT_UNREAD');
            commit('SET_LATEST_CHAT_EVENT', rawMessage)            
        },
        PROCESS_MESSAGE_UNSENT_EVENT({dispatch, commit , state, rootGetters}, rawMessage) {
            commit('SET_LATEST_CHAT_EVENT', rawMessage)            
        },
        PROCESS_NOTIFICATION() {

        },        
    }
}